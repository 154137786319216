import { Link } from '@remix-run/react';
import { Image } from '@rocco/components/image';
import type { ImageModel } from '@rocco/types/image-model';

export interface DetailsPageIslandImageProps {
    image: ImageModel;
    background?: string | null;
    link?: string;
    size?: 'full' | 'professionalLogo';
}

export const DetailsPageIslandImage = (props: DetailsPageIslandImageProps) => {
    const { image, background, link, size = 'full' } = props;

    const imageElement = (
        <Image
            src={image.src}
            alt={image.alt ?? 'Logo'}
            size={size}
            fit={image.multiplied ? 'contain' : 'cover'}
        />
    );

    return (
        <div
            className="flex shrink-0 size-18 items-center justify-center overflow-hidden rounded-full"
            style={{
                backgroundColor: background || 'white',
            }}
        >
            {link ? (
                <Link to={link} className="flex items-center justify-center">
                    {imageElement}
                </Link>
            ) : (
                imageElement
            )}
        </div>
    );
};
