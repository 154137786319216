import { FloatingIsland } from '../FloatingIsland';
import { DisplayMode } from '@rocco/types/enum/display-mode';

import {
    DetailsPageIslandImage,
    type DetailsPageIslandImageProps,
} from './DetailsPageIslandImage';

interface DetailsPageIslandProps {
    image?: DetailsPageIslandImageProps;
    title: string;
    description: string | React.ReactNode;
    primaryAction: React.ReactNode;
    secondaryAction: React.ReactNode;
    displayMode: DisplayMode;
    scrollThreshold?: number;
}

export const DetailsPageIsland = ({
    image,
    title,
    description,
    primaryAction,
    secondaryAction,
    displayMode,
    scrollThreshold = 100,
}: DetailsPageIslandProps) => {
    const showImage = image && displayMode === DisplayMode.Desktop;
    const variant = showImage ? 'details' : 'detailsNoImage';

    return (
        <FloatingIsland scrollThreshold={scrollThreshold} variant={variant}>
            <div className="flex gap-2.5 md:gap-6 items-center md:min-h-18">
                {showImage ? <DetailsPageIslandImage {...image} /> : null}
                <div className="flex flex-col gap-1 md:gap-2 min-w-0 flex-1 items-start">
                    <span className="text-sm md:text-base md:leading-[1.25] font-medium text-ellipsis overflow-hidden whitespace-nowrap cursor-default w-full">
                        {title}
                    </span>
                    {typeof description === 'string' ? (
                        <p className="text-3 md:text-sm tracking-[0.0175rem] text-gray-500 text-ellipsis overflow-hidden cursor-default">
                            {description}
                        </p>
                    ) : (
                        description
                    )}
                </div>
                <div className="flex gap-2.5 shrink-0">
                    {secondaryAction}
                    {primaryAction}
                </div>
            </div>
        </FloatingIsland>
    );
};
